<template>
    <section class="r-landing-banner">
        <swiper
            :modules="modules"
            :slides-per-view="1"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :pagination="pagination"
            :autoplay="autoplay"
            :loop="true"
        >
            <swiper-slide v-for="(b,i) in banner" :key="`banner-${i}`">
                <a :href="b.link" class="r-center-flex r-banner"  rel="noreferrer noopener nofollow" target="_blank">
                    <div class="r-banner-img" :style="`background-image: url(${b.full_path})`">
                    </div>
                </a>    
            </swiper-slide>
        </swiper>
    </section>
    <!-- <section :href="currentBanner().link" class="r-landing-banner" >
        <a :href="currentBanner().link" class="r-center-flex r-isExpand"  rel="noreferrer noopener nofollow" target="_blank">
                <img class="r-isExpand" :src="currentBanner().full_path" alt="pattern">
        </a>
        <div class="r-mb-40 r-slider-button" v-if="banner.length > 1">
            <div class="r-slider-pages r-center-flex">
                <div class="r-slider-page" 
                    v-for="i in banner.length" 
                    :key="`banner-slide-${i}`"
                    :class="imgIndex == i-1 ? 'r-slider-page-active' : ''" @click="imgIndex = i-1"></div>
            </div>
        </div>
    </section> -->
</template>
<script>
// Core modules imports are same as usual
import { Pagination, Autoplay } from 'swiper';
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

import { mapGetters, mapActions} from 'vuex'

export default {
    components: {
      Swiper,
      SwiperSlide,
      
    },
    computed: {
        ...mapGetters({
            banner: 'landingPage/getBanner',
        }),
    },
    mounted(){
        this.initData()
    },
    data: () => ({
        imgIndex: 0,
        modules: [Pagination, Autoplay],
        pagination: { clickable: true, dynamicBullets: true },
        autoplay: { delay: 2000}
    }),
    methods: {
        ...mapActions({
            getBannerData: 'landingPage/getBannerData',
        }),
        async initData(){
            await this.getBannerData()
        },
        currentBanner(){
            if(this.banner.length == 0 || this.imgIndex >= this.banner.length) return {full_path: require('@/assets/image/pattern.png'), link: '/'}
            return this.banner[this.imgIndex]
        },
        gotoLink(link){
            window.open(link)
        },
        onSwiper(){

        },
        onSlideChange(){

        }
    }
}
</script>