<template>
    <Carousel />
    <RegistrationBanner 
        :options="{
            img: registration.full_path,
            alt: registration.alt,
            description: registration.registration_subtitle,
            title: registration.registration_title,
            is_active: registration.registration_is_active == 1,
            color: 'primary'
        }"
    ></RegistrationBanner>
    <section class="r-ptb-80">
        <div class="r-container">
            <div class="r-learning">
                <h3 class="r-mlr-auto r-white-text r-mtb-40">Unit Pendidikan</h3>
                <div class="r-center-flex r-isExpand r-isWrap">
                    <div class="r-column r-column-4 " v-for="(u,i) in edu_units" :key="`unit-${i}`">
                        <div class="r-learning-class" >
                            <router-link class="r-center-flex r-mlr-auto" :to="{name: 'UnitProfile', params: {slug: u.slug}}">
                                <div class="r-square-50 r-center-flex">
                                    <img class="r-isExpand" :src="u.full_path" :alt="u.alt">
                                </div>
                                <span class="r-ml-10">{{u.name_short}}</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="r-ptb-80">
        <div class="r-container">
            <div class="r-flex r-isWrap">
                <div class="r-column r-column-2">
                    <div class="r-landing-video r-center-flex r-isRelative r-isClickAble"  @click="modal = 'youtube'">
                        <div class="r-landing-thumbnail-place r-isRelative r-mlr-auto r-isFlex">
                            <img class="r-landing-thumbnail r-isExpand r-isRelative" :src="`https://img.youtube.com/vi/${youtube || 'WEORb8dlRZo'}/hqdefault.jpg`" alt="Kondisi Masehi Kudus Terkini 2020">
                            <img class="r-landing-play-button r-position-center" :src="require('@/assets/image/play.png')" alt="">
                            <div class="r-landing-shadow"></div>
                        </div>
                    </div>
                </div>
                <div class="r-column r-column-2 r-flex">
                    <div>
                        <h2 class="r-mb-16">Transformasi Yayasan Bina Pelayanan Masehi Kudus</h2>
                        <p class="r-mb-32">Yayasan Badan Pendidikan Masehi (Sekarang Yayasan Bina Pelayanan Masehi) didirikan oleh Jemaat Gereja Muria Indonesia di Kudus (GKMI Kudus), dengan dibuatnya Akte pendirian Yayasan di hadapan Notaris R.M.Poerbokoesoemo pada 31 Desember 1957 dan jemaat Gereja itu sendiri sudah ada di Kudus sejak tahun 1920.</p>
                        <h2 class="r-mb-25">Motto</h2>
                        <div class="r-flex r-mtb-10 r-isExpand r-isWrap">
                            <div class="r-moto r-moto-left">
                                <span class="r-mlr-auto">
                                    Tinggi Iman
                                </span>
                            </div>
                            <div class="r-moto r-moto-middle">
                                <span class="r-mlr-auto">
                                    Tinggi Ilmu
                                </span>
                            </div>
                            <div class="r-moto r-moto-right">
                                <span class="r-mlr-auto">
                                    Tinggi Pengabdian
                                </span>
                            </div>
                        </div>
                        <router-link :to="{name: 'FoundationProfile'}" class="r-mt-40 ">
                            <ButtonCustom class="r-plr-8">
                                <div class="r-center-flex">
                                    <span class="r-isBold r-mr-10">
                                        Pelajari Lebih Lanjut
                                    </span>
                                    <img :src="require('@/assets/icons/white/arrow-right.svg')" alt="arrow-right">
                                </div>
                            </ButtonCustom>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="r-superior-program r-ptb-60 r-isRelative">
        <div class="r-superior-bg"></div>
        <div class="r-container r-isRelative">
            <h3 class="r-mb-20 r-white-text">Program Unggulan</h3>
            <p class="r-white-text">Berikut ini adalah program unggulan yang bertujuan untuk meningkatkan mutu dari kegiatan <br> pembelajaran di Yayasan Bina Pelayanan Masehi Kudus.</p>
        </div>
        <div class="r-container r-isRelative r-mt-60">
            <div class="r-superior-items r-center-flex">
                <div class="r-box r-p-16 r-superior-item" v-for="i in 8" :key="`superior-${i}`">
                    <img class="r-mb-10 r-wd-50" :src="require('@/assets/image/glass/Discovery.png')" alt="Discovery">
                    <h4 class="r-mb-8">Kurikulum Karater</h4>
                    <p>Kegiatan pembelajaran yang mengacu kepada pendidikan karakter.</p>
                </div>
            </div>
        </div>
    </section> -->
    <section class="r-achievement r-ptb-60">
        <CardView title="Prestasi" :list="achievements" type="prestasi" :route="{name: 'ContentList', params: {contentType: 'prestasi'}}"></CardView>
    </section>
    <section class="r-blog r-ptb-60">
        <CardView title="Blog" :list="blogs" type="blog" :route="{name: 'ContentList', params: {contentType: 'blog'}}"></CardView>
    </section>
    <Facilities :list="facilities" ></Facilities>
    <YoutubeEmbed v-if="modal == 'youtube'" :youtubeId="youtube || 'WEORb8dlRZo'" @closeModal="closeModal"></YoutubeEmbed>
</template>
<script>
import common from '@/mixins/common'
import Facilities from '@/components/home/Facilities'
import RegistrationBanner from '@/components/home/RegistrationBanner'
import Carousel from '@/components/home/Carousel'
import CardView from '@/components/home/CardView'
import YoutubeEmbed from '@/components/helper/YoutubeEmbed'
import { mapGetters, mapActions} from 'vuex'
export default {
    mixins: [common],
    components: {
        Facilities,
        RegistrationBanner,
        Carousel,
        CardView,
        YoutubeEmbed
    },
    computed: {
        ...mapGetters({
            youtube: 'setting/getVideoProfil',
            facilities: 'landingPage/getFacilities',
            articles: 'landingPage/getArticles',
            edu_units: 'landingPage/getEduUnits',
            registration: 'landingPage/getRegistration'
        }),
        blogs(){
            return this.articles('blog')
        },
        achievements(){
            return this.articles('prestasi')
        },
    },
    mounted(){
        this.initData()
    },
    data: () => ({
        modal: null,
        imgIndex: 0,
        units: [
            {
                name: 'PAUD',
                route: {name: 'UnitProfile', params: {unit: 'paud'}}
            },
            {
                name: 'SD',
                route: {name: 'UnitProfile', params: {unit: 'sd'}}
            },
            {
                name: 'SMP',
                route: {name: 'UnitProfile', params: {unit: 'smp'}}
            },
            {
                name: 'SMA',
                route: {name: 'UnitProfile', params: {unit: 'sma'}}
            },
        ],
    }),
    methods: {
        ...mapActions({
            getFacilitiesData: 'landingPage/getFacilitiesData',
            getData: 'setting/fetchData',
            getArticlesData: 'landingPage/getArticlesData',
            getRegistrationData: 'landingPage/getRegistrationData',
        }),
        closeModal(){
            this.modal = null
        },
        async initData(){
            this.getRegistrationData()
            this.getFacilitiesData()
            this.getData('video-profil')
            this.getArticlesData({filter: 'ACHIEVEMENT', key: 'prestasi'})
            this.getArticlesData({filter: 'BLOG', key: 'blog'})
        },
    }
}
</script>