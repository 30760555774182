<template>
    <nav class="r-navbar" :class="navbarClass()">
        <div class="r-isRelative r-keep-view">
            <div class="r-center-flex r-container">
                <router-link :to="{name: 'LandingPage'}" class="r-center-flex r-mr-auto">
                    <div class="r-wd-40-max r-mr-10 r-center-flex">
                        <img class="r-isExpand" :src="require('@/assets/logo.png')" alt="Logo">
                    </div>
                    <p :class="ypos == 0 && !isSticky ? 'r-white-text' : ''" class="r-isBold r-ellipsis">YBPM Kudus</p>
                </router-link>
                <div class="r-center-flex r-ml-auto" >
                    <router-link class="r-isExpand" :to="loginButton().to" v-if="!isMobile">
                        <ButtonCustom :color="ypos == 0 && !isSticky ? 'white' : 'primary'" >{{loginButton().label}}</ButtonCustom>
                    </router-link>  
                    <img class="r-isClickAble" @click.prevent="mobileMenu = true" :src="require(`@/assets/icons/${ypos == 0 && !isSticky ? 'white' : 'primary'}/menu.svg`)" alt="White Menu" v-else>
                </div>
            </div>
            <div class="r-center-flex r-position-center r-navbar-button" v-if="!isMobile">
                <router-link :to="{name: 'LandingPage'}" class="r-wd-100-min r-navbar-menu r-600">Beranda</router-link>
                <div class="r-wd-100-min r-navbar-menu" @mouseenter="hover.unit = true" @mouseleave="hover.unit = false">
                    <div class="r-center-flex r-navbar-item">
                        <p class="r-mr-8 r-ellipsis r-600">Unit Pendidikan</p>
                        <img :src="require(`@/assets/icons/${currentIcon()}/arrow-down.svg`)" alt="arrow-down">
                    </div>
                    <div class="r-dropdown" v-if="hover.unit">
                        <div class="r-dropdown-place r-mt-10">
                            <div class="r-dropdown-item r-isExpand">
                                <router-link class="r-center-flex r-mtb-8" 
                                    v-for="(u,i) in edu_units" :key="`unit-${i}`"
                                    @mouseenter="hover.unitKey = u.slug" @mouseleave="hover.unitKey = null"
                                    :to="{name: 'UnitProfile', params: {slug: u.slug}}"
                                    >
                                    <div class="r-square-50 r-center-flex">
                                        <img class="r-isExpand" :src="u.full_path" :alt="u.alt">
                                    </div>
                                    <div class="r-ml-10 r-mr-20 ">
                                        <p :class="hover.unitKey == u.slug ? 'r-primary-text' :'r-base-80-text'"><strong>{{u.name_short}}</strong></p>
                                        <p class="r-base-80-text r-ellipsis">Pelajari Lebih Lanjut {{u.name_short}}</p>
                                    </div>
                                    <div class="r-center-flex r-ml-auto r-wd-40-min">
                                        <img class="r-ml-auto" v-show="hover.unitKey == u.slug" :src="require(`@/assets/icons/primary/arrow-right.svg`)" alt="arrow-right">
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="r-wd-100-min r-navbar-menu" @mouseenter="hover.foundation = true" @mouseleave="hover.foundation = false">
                    <div class="r-center-flex r-navbar-item">
                        <p class="r-mr-8 r-ellipsis r-600">Yayasan</p>
                        <img :src="require(`@/assets/icons/${currentIcon()}/arrow-down.svg`)" alt="arrow-down">
                    </div>
                    <div class="r-dropdown" v-if="hover.foundation">
                        <div class="r-dropdown-place r-mt-10">
                            <div class="r-dropdown-item r-isExpand">
                                <router-link class="r-center-flex r-mtb-8" 
                                    v-for="(u,i) in foundations" :key="`foundations-${i}`"
                                    :to="u.route"
                                    @mouseenter="hover.foundationKey = u.id" @mouseleave="hover.foundationKey = null"
                                    >
                                    <div class="r-square-24">
                                        <img class="r-isExpand" :src="u.img" :alt="u.name">
                                    </div>
                                    <div class="r-ml-10 r-mr-20 ">
                                        <p class="r-ellipsis" :class="hover.foundationKey == u.id ? 'r-primary-text' :'r-base-80-text'"><strong>{{u.name}}</strong></p>
                                    </div>
                                    <div class="r-center-flex r-ml-auto r-wd-40-min">
                                        <img class="r-ml-auto" v-show="hover.foundationKey == u.id" :src="require(`@/assets/icons/primary/arrow-right.svg`)" alt="arrow-right">
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <router-link :to="{name: 'Ikamaku'}" class="r-wd-100-min r-navbar-menu r-600">
                    <p class="r-ellipsis r-navbar-item">Ikatan Alumni</p></router-link>
                <router-link :to="{name: 'Contact'}" class="r-wd-100-min r-navbar-menu r-600">
                    <p class="r-navbar-item">Kontak</p></router-link>
            </div>
            <div class="r-mobilenavbar" v-if="isMobile" :style="mobileMenu ? 'left: 0px' : 'left: 100%;'" @click.prevent="mobileMenu = false">
                <div class="r-header r-center-flex r-plr-16 r-white-bg">
                    <h3>Menu</h3>
                    <img :src="require('@/assets/icons/primary/close.svg')"  alt="close" class="r-ml-auto" >
                </div>
                <div class="r-mobilenavbar-menu ">

                    <router-link :to="{name: 'LandingPage'}" class="r-mobilenavbar-menu-item">
                        <div class="r-center-flex r-mr-20">
                            <span class="r-red-70-text">
                                Beranda
                            </span>
                        </div>
                    </router-link>
                    <div class="r-mobilenavbar-menu-item" @click.stop="showUnit = !showUnit">
                        <div class="r-center-flex r-mr-20">
                            <span class="r-red-70-text r-mr-auto">
                                Unit Pendidikan
                            </span>
                             <img :class="showUnit ? 'r-vrotate' : ''" :src="require(`@/assets/icons/black/arrow-down.svg`)" alt="arrow-down">
                        </div>
                    </div>
                    <template v-if="showUnit">
                        <router-link :to="{name: 'UnitProfile', params: {slug: d.slug}}" class="r-mobilenavbar-menu-item-2" v-for="(d,i) in edu_units" :key="`unit-${i}`">
                            <div class="r-center-flex r-mr-20">
                                <span class="r-red-70-text">
                                    {{d.name_short}}
                                </span>
                            </div>
                        </router-link>
                    
                    </template>
                    <div class="r-mobilenavbar-menu-item" @click.stop="showFoundation = !showFoundation">
                        <div class="r-center-flex r-mr-20">
                            <span class="r-red-70-text r-mr-auto">
                                Yayasan
                            </span>
                             <img :class="showFoundation ? 'r-vrotate' : ''" :src="require(`@/assets/icons/black/arrow-down.svg`)" alt="arrow-down">
                        </div>
                    </div>
                    <template v-if="showFoundation">
                        <router-link :to="{name: 'FoundationProfile'}" class="r-mobilenavbar-menu-item-2">
                            <div class="r-center-flex r-mr-20">
                                <span class="r-red-70-text">
                                    Profil Yayasan
                                </span>
                            </div>
                        </router-link>
                        <router-link :to="{name: 'OrganizationStructure'}" class="r-mobilenavbar-menu-item-2">
                            <div class="r-center-flex r-mr-20">
                                <span class="r-red-70-text">
                                    Struktur Organisasi Yayasan
                                </span>
                            </div>
                        </router-link>
                    </template>
                    <router-link :to="{name: 'Ikamaku'}" class="r-mobilenavbar-menu-item">
                        <div class="r-center-flex r-mr-20">
                            <span class="r-red-70-text">
                                Ikatan Alumni
                            </span>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'Contact'}" class="r-mobilenavbar-menu-item">
                        <div class="r-center-flex r-mr-20">
                            <span class="r-red-70-text">
                                Kontak
                            </span>
                        </div>
                    </router-link>
                    <div class="r-mobilenavbar-menu-item">
                        <router-link class="r-isExpand" :to="loginButton().to">
                            <ButtonCustom class="r-isExpand">{{loginButton().label}}</ButtonCustom>
                        </router-link>
                    </div>
                    
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
import ButtonCustom from '@/components/helper/Button'
import { mapGetters, mapActions } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            isSticky: 'getIsSticky',
            isMobile: 'getIsMobile',
            isLoggedIn: 'getisLoggedIn',
            edu_units: 'landingPage/getEduUnits'
        })
    },
    components: {
        ButtonCustom
    },
    mounted(){
        this.initData()
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    uncreated () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    data: () => ({
        ypos: 0,
        mobileMenu: false,
        showUnit: false,
        showFoundation: false,
        hover: {
            unit: false,
            foundation: false,
            foundationKey: null,
            unitKey: null,
        },
        units: [
            {
                id: 'PAUD',
                name: 'PAUD',
                img: require('@/assets/image/grade/PAUD.png'),
                route: {name: 'UnitProfile', params: {unit: 'paud'}}
            },
            {
                id: 'SD',
                name: 'SD',
                img: require('@/assets/image/grade/SD.png'),
                route: {name: 'UnitProfile', params: {unit: 'sd'}}
            },
            {
                id: 'SMP',
                name: 'SMP',
                img: require('@/assets/image/grade/SMP.png'),
                route: {name: 'UnitProfile', params: {unit: 'smp'}}
            },
            {
                id: 'SMA',
                name: 'SMA',
                img: require('@/assets/image/grade/SMA.png'),
                route: {name: 'UnitProfile', params: {unit: 'sma'}}
            },
        ],
        foundations: [
            {
                id: 'Profile',
                name: 'Profil Yayasan',
                img: require('@/assets/icons/foundation/home.png'),
                route: {name: 'FoundationProfile'}
            },
            {
                id: 'Structure',
                name: 'Struktur Organisasi Yayasan',
                img: require('@/assets/icons/foundation/category.png'),
                route: {name: 'OrganizationStructure'}
            },
        ],
        
    }),
    methods: {
        ...mapActions({
            getEduUnitsData: 'landingPage/getEduUnitsData'
        }),
        handleScroll () {
            this.ypos = window.pageYOffset
        },
        currentIcon(){
            if(this.isSticky) return 'primary'
            return this.ypos == 0 ? 'white' : 'primary'
        },
        navbarClass(){
            if(this.isSticky) return 'r-navbar-scroll r-isSticky'
            return this.ypos == 0 ? 'r-navbar-top' : 'r-navbar-scroll'
        },
        loginButton(){
            if(!this.isLoggedIn) {
                return {
                    to: {name: 'Login'},
                    label: 'Masuk'
                }
            }else {
                return { 
                    to: {name: 'Siakad'},
                    label: 'Siakad'
                }
            }
        },
        async initData() {
            await this.getEduUnitsData()
        }
    }
}
</script>