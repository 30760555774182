<template>
    <section class="r-footer">
        <div class="r-container">
            <div class=" r-isWrap" :class="!isMobile ? 'r-columns' : 'r-flex'">
                <div :class="isMobile ? 'r-isExpand' : 'r-column r-is-half'">
                    <h5 class="r-mb-16">Yayasan Bina Pelayanan Masehi Kudus</h5>
                    <p class="r-mb-16">Jl. K.H. Wahid Hasim No.29-E, Magersari, Demaan, Kec. Kota, Kabupaten Kudus, Jawa Tengah 59317</p>
                     <a href="https://goo.gl/maps/bfReEA7qKSufQip78" target="_blank" rel="noreferrer noopener nofollow">
                        <ButtonCustom type="textOnly" padding="0" class="r-plr-0">
                            <div class="r-center-flex">
                                <span class="r-isBold r-mr-10">
                                    Lihat Alamat di Peta
                                </span>
                                <img :src="require('@/assets/icons/primary/arrow-right.svg')" alt="arrow-right">
                            </div>
                        </ButtonCustom>
                    </a>
                </div>
                <div :class="!isMobile ? 'r-column r-column-5' : 'r-mtb-12'">
                    <h5 class="r-mb-16">Sosial Media</h5>
                    <div >
                        <a href="https://www.facebook.com/masehi.kudus" 
                            target="_blank" 
                            rel="nofollow noreferrer noopener"
                            class="r-center-flex r-mb-10">
                            <div class="r-flex r-mr-10">
                                <img class="r-wd-24" :src="require('@/assets/icons/social-media/fb.svg')" alt="facebook">
                            </div>
                            <span>masehi.kudus</span>
                        </a>
                        <a href="https://twitter.com/YbpmK" 
                            target="_blank" 
                            rel="nofollow noreferrer noopener"
                            class="r-center-flex r-mb-10">
                            <div class="r-flex r-mr-10">
                                <img class="r-wd-24" :src="require('@/assets/icons/social-media/tw.svg')" alt="twitter">
                            </div>
                            <span>YbpmK</span>
                        </a>
                        <a href="https://www.instagram.com/ybpmkudus" 
                            target="_blank" 
                            rel="nofollow noreferrer noopener"
                            class="r-center-flex r-mb-10">
                            <div class="r-flex r-mr-10">
                                <img class="r-wd-24" :src="require('@/assets/icons/social-media/ig.svg')" alt="instagram">
                            </div>
                            <span>ybpmkudus</span>
                        </a>
                        <a href="https://www.youtube.com/channel/UCXjdH6xJvKKg12DDTzkyGKw" 
                            target="_blank" 
                            rel="nofollow noreferrer noopener"
                            class="r-center-flex r-mb-10">
                            <div class="r-flex r-mr-10">
                                <img class="r-wd-24" :src="require('@/assets/icons/social-media/yt.svg')" alt="youtube">
                            </div>
                            <span>sekolah masehi</span>
                        </a>
                    </div>
                </div>
                <div :class="!isMobile ? 'r-column' : 'r-isExpand'">
                    <h5 class="r-mb-16">Akreditasi</h5>
                    <div class="r-flex r-isWrap">
                        
                        <div class="r-column r-column-2" 
                            v-for="(a,i) in accreditations"
                            :key="`accreditation-${i}`"
                            >
                            <div class="r-mb-12 r-center-flex">
                                <div class="r-flex r-mr-10 r-wd-40">
                                    <img class="r-isExpand" :src="a.full_path" :alt="a.alt">
                                </div>
                                <p> <span class="r-isBold">
                                    {{a.short_name}}
                                    </span>  <br>
                                   {{a.accreditation}}
                                </p>
                            </div>
                        </div>
                        
                        
                       
                    </div>
                   
                </div>
            </div>
            <div class="r-mt-40 r-copyright">
                <p>Copyright © {{currentYear}} Sekolah Masehi Kudus</p>
            </div>
        </div>
    </section>
</template>
<script>
import ButtonCustom from '@/components/helper/Button'
const today = new Date()
import { mapGetters, mapActions } from 'vuex'

export default {
    components: {
        ButtonCustom
    },
    computed: { 
        ...mapGetters({
            isMobile: 'getIsMobile',
            accreditations: 'landingPage/getAccreditation'
        }),
        currentYear(){
            return today.getFullYear()
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        ...mapActions({
            getAccreditationData: 'landingPage/getAccreditationData'
        }),
        async initData() {
            await this.getAccreditationData()
        }
    }
}
</script>