<template>
    <section class="r-isExpand" :class="backgroundImage()">
        <div class="r-container">
            <div class="r-flex r-isWrap">
                <img class="r-mr-40 r-wd-230-max" :src="options.img" :alt="options.alt" v-if="!isMobile">
                <div class="r-center-flex">
                    <div>
                        <h2 class="r-mb-10">{{options.title}}</h2>
                        <p>{{options.description}}</p>
                    </div>
                </div>
                <div class="r-center-flex" :class="!isMobile ? 'r-ml-auto ' : 'r-mtb-20'">
                    <div class="r-center-flex" :class="!isMobile ? 'r-pl-40' : ''">
                        <router-link :to="loginButton().to" :class="!options.is_active ? 'r-disabled-event': ''">
                            <ButtonCustom :disabled="!options.is_active" class="r-plr-8" :color="options.color">
                                <div class="r-center-flex">
                                    <span class="r-isBold r-mr-10">
                                        {{button}}
                                    </span>
                                    <img :src="require('@/assets/icons/white/arrow-right.svg')" alt="arrow-right">
                                </div>
                            </ButtonCustom>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import ButtonCustom from '@/components/helper/Button'
const today = new Date()
export default {
    components: {
        ButtonCustom
    },
    computed: {
        isLoggedIn(){
            return this.$store.getters['getisLoggedIn']
        },
        isMobile(){
            return this.$store.getters['getIsMobile']
        },
        unit(){
            return this.$route.params.unit
        },
    },
    props: {
        options: {
            default: () => ({
                img: require('@/assets/image/registration.png'),
                alt: 'Pendaftaran',
                title: `Penerimaan Peserta Didik Baru Tahun Ajaran ${today.getFullYear()}`,
                is_active: true,
                description: 'Segera daftarkan dirimu untuk menjadi bagian dari keluarga besar Yayasan Bina Pelayanan Masehi Kudus.',
                color: 'primary'
            })
        },
        button: {
            default: 'Daftar Sekarang'
        }
    },
    methods: {
        currentYear(){
            return today.getFullYear()
        },
        backgroundImage(){
            return 'r-registration' + (!this.unit ? '' : `-${this.unit}`)
        },
        loginButton(){
            if(!this.isLoggedIn) {
                return {
                    to: {name: 'Welcome'},
                    label: 'Masuk'
                }
            }else {
                return { 
                    to: {name: 'Siakad'},
                    label: 'Siakad'
                }
            }
        }
    }
}
</script>